import { AgdirApiEnvironment, AgdirAwsEnvironment, AgdirGoogleEnvironment, AgdirOneSignalEnvironment, Environment } from '@agdir/environment/domain';
import { weHaveSharedMfas } from './we-have-shared-mfas';
import { weHaveSharedVendors } from './we-have-shared-vendors';

export const environment = new Environment({
	env: 'stg',
	production: true,
	remotes: new Map([['farmSiteUrl', 'https://stg.agdir.dev']]),
});
environment
	.register(
		new AgdirAwsEnvironment({
			region: 'eu-west-1',
			userPoolId: 'eu-west-1_Og3uNK3ju',
			userPoolWebClientId: '7mm8mb0mf70uh1pt8fggs6hdp2',
		}),
	)
	.register(
		new AgdirApiEnvironment({
			webHost: 'http://stg.agdir.dev',
			hostv3: 'https://v3.api.stg.agdir.dev',
			host: 'https://api.stg.agdir.dev',
		}),
	)
	.register(
		new AgdirGoogleEnvironment({
			apiKey: 'AIzaSyAd79l2jlY5ekNi-oX7lpbJbtNt6iP9_1c',
			reCaptchaV3SiteKey: '6LftOU0hAAAAAGvXGVjMOi-PUUxs6luj9GNiWp5G',
			googleAnalyticsTrackingCode: 'G-Q671GR7GRR',
		}),
	)
	.register(
		new AgdirOneSignalEnvironment({
			appId: '294c89cb-e89f-417a-9d63-468d836e0d0f',
			safari_web_id: 'web.onesignal.auto.52db6e33-5c43-4c7e-8893-c04dfe7146e4',
			notifyButton: {
				enable: true,
			},
		}),
	);

weHaveSharedMfas(environment);
weHaveSharedVendors(environment);
